import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ProTable } from "@ant-design/pro-components";
import { Button, Input, ConfigProvider } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import enUS from "antd/es/locale/en_US";
import api_address from "../../../constants/config";
import { ADMIN_COOKIE_NAME, LIMIT } from "../../../constants/admin";
import { JS_COOKIE } from "util/auth";
import { AdminTableContainer } from "../styles";
import { PRODUCTS } from "../../../constants/common";

interface ResultType {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  productName: string;
  currentStep: string;
  completed: string;
  transactionId: string;
}

interface SorterState {
  field: string;
  order: "ascend" | "descend" | undefined;
}

const Result: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [resultData, setResultData] = useState<ResultType[]>([]);
  const [filteredData, setFilteredData] = useState<ResultType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sorter, setSorter] = useState<SorterState | null>(null);
  const [filters, setFilters] = useState<{
    currentStep?: string[];
    productName?: string[];
  }>({});
  const [searchText, setSearchText] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(25);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleRowDoubleClick = (record: ResultType) => {
    history.push(`/admin/result/edit/${record.id}`);
  };

  const getResultData = async (page: number = 1, pageSize: number = 25) => {
    setLoading(true);
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const offset = (page - 1) * pageSize;
      const { data } = await axios.get(api_address + "api/admin/result", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          filter: searchText,
          offset: offset,
          limit: pageSize,
        },
      });
      const sortedData = sortData(data.result, sorter);
      setResultData(sortedData);
      setFilteredData(sortedData);
      setTotal(data.rowCount);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getResultData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [filters, sorter, resultData, searchText]);

  const applyFiltersAndSort = () => {
    let result = [...resultData];

    if (searchText) {
      result = result.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }

    if (filters.currentStep && filters.currentStep.length > 0) {
      result = result.filter((item) =>
        filters.currentStep!.includes(item.currentStep)
      );
    }

    if (filters.productName && filters.productName.length > 0) {
      result = result.filter((item) =>
        filters.productName!.includes(item.productName)
      );
    }

    result = sortData(result, sorter);

    setFilteredData(result);
    setTotal(result.length);
  };

  const sortData = (data: ResultType[], currentSorter: SorterState | null) => {
    if (!currentSorter) return data;

    return [...data].sort((a, b) => {
      if (currentSorter.order === "ascend") {
        return a[currentSorter.field] > b[currentSorter.field] ? 1 : -1;
      } else if (currentSorter.order === "descend") {
        return a[currentSorter.field] < b[currentSorter.field] ? 1 : -1;
      }
      return 0;
    });
  };

  const productFilters = Object.keys(PRODUCTS).map((key) => ({
    text: PRODUCTS[key].replace(/([A-Z])/g, " $1").trim(),
    value: PRODUCTS[key],
  }));

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    const newSorter: SorterState = {
      field: sorter.field,
      order: sorter.order,
    };
    setSorter(newSorter);
    setFilters(filters);
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.current);
    getResultData(pagination.current, pagination.pageSize);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  const columns = [
    {
      title: t("admin.result.id"),
      dataIndex: "id",
      sorter: true,
    },
    {
      title: t("admin.user.id"),
      dataIndex: "userId",
      sorter: true,
      render: (text: string) => (
        <a onClick={() => history.push(`/admin/user/edit/${text}`)}>{text}</a>
      ),
    },
    {
      title: t("admin.result.firstName"),
      dataIndex: "firstName",
      sorter: true,
    },
    {
      title: t("admin.result.lastName"),
      dataIndex: "lastName",
      sorter: true,
    },
    {
      title: t("admin.result.transactionId"),
      dataIndex: "transactionId",
      sorter: true,
    },
    {
      title: t("admin.payment.product"),
      dataIndex: "productName",
      sorter: false,
      filters: productFilters,
    },
    {
      title: t("admin.user.currentStep"),
      dataIndex: "currentStep",
      hideInSearch: true,
      render: (text: string) => {
        if (!text || text === "-") {
          return "-";
        }
        return t(`admin.user.${text}`);
      },
      filters: [
        { text: "Therapist reviewing", value: "reviewing" },
        { text: "Child information", value: "child_info" },
        { text: "Upload video", value: "video" },
        { text: "Report ready for review", value: "report" },
        { text: "Evaluation", value: "questionnaire" },
        { text: "Instructions", value: "basic_intro" },
        { text: "Waiting for assign", value: "waiting" },
        { text: "Sound", value: "sound_collection" },
        { text: "Instructions", value: "kks_intro" },
      ],
    },
    {
      title: t("admin.result.completed"),
      dataIndex: "completed",
      sorter: true,
      render: (text: string) =>
        text === "true" ? (
          <span style={{ color: "green" }}>
            <CheckCircleOutlined /> Yes
          </span>
        ) : (
          <span style={{ color: "red" }}>
            <CloseCircleOutlined /> No
          </span>
        ),
    },
  ];

  return (
    <ConfigProvider locale={enUS}>
      <AdminTableContainer>
        <Input.Search
          placeholder={t("admin.payment.transactionId")}
          onChange={handleSearch}
          style={{ marginBottom: 16 }}
        />
        <ProTable<ResultType>
          columns={columns}
          dataSource={filteredData}
          loading={loading}
          rowKey="id"
          pagination={{
            showQuickJumper: true,
            total: total,
            pageSize: pageSize,
            current: currentPage,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "25", "50", "100"],
          }}
          search={false}
          dateFormatter="string"
          headerTitle={t("admin.result.title")}
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setSelectedRowKeys(selectedRowKeys);
            },
          }}
          onRow={(record) => ({
            onDoubleClick: () => handleRowDoubleClick(record),
          })}
          toolBarRender={() => [
            selectedRowKeys.length === 1 && (
              <Button
                key="edit"
                onClick={() =>
                  history.push(`/admin/result/edit/${selectedRowKeys[0]}`)
                }
              >
                {t("admin.result.edit")}
              </Button>
            ),
          ]}
          onChange={handleTableChange}
        />
      </AdminTableContainer>
    </ConfigProvider>
  );
};

export default Result;
