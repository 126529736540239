import { Component } from "react";
import { match } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../../constants/config";
import { getYearAndMonth } from "../../../../util/result";
import Cookies from "js-cookie";
import { History, LocationState } from "history";
import moment from "moment";
import {
  EXPERT_PRODUCTS,
  PRODUCTS,
  EXPERT_PRODUCTS_WITHOUT_REPORT,
  EXPERT_PRODUCTS_FOR_ADMIN_PANEL,
  PRODUCTS_WITH_CHILD_INFO_ONLY,
} from "../../../../constants/common";
import NotificationModal from "../../../common/NotificationModal";
import VideoModal from "../../../common/VideoModal";
import AssignTherapistModal from "../../../common/AssignTherapistModal";
import { SNOWFLAKE } from "../../../../util/common";
import Uploader from "../../../../util/uploader";
import CommentModal from "../../../common/CommentModal";
import {
  Container,
  ContentContainer,
  ContentSubtitle,
  Input,
  CancelButton,
  SaveButton,
  LinkButtonInEdit,
} from "../../styles";
import {
  SubContainer,
  CompletedContainer,
  CompletedButton,
  ChildInfoContainer,
  ChildInfoTitle,
  TherapistContainer,
  TherapistTitle,
  BasicInfoContainer,
  BasicInfoTitle,
  MoreInfoContainer,
  MoreInfoTitle,
  VideoContainer,
  VideoTitle,
  ReportContainer,
  ReportTitle,
  BasicInfoContent,
  ChildName,
  ChildAge,
  FullResult,
  Report,
  Reject,
  Submit,
  Video,
  Assign,
  Edit,
} from "./style";
import { JS_COOKIE } from "util/auth";
import { ADMIN_COOKIE_NAME } from "constants/admin";
import { client } from "graphql/ApolloClient";
import { ADD_CHILDREN_MUTATION } from "graphql/mutations/addChildren";

interface Match {
  id: string;
}

interface TherapistType {
  id: string;
  firstName: string;
  lastName: string;
  UUID: string;
}

interface ResultEditProps {
  match: match<Match>;
  history: History<LocationState>;
}

interface childInfoType {
  childBirthdayDay: string;
  childBirthdayMonth: string;
  childBirthdayYear: string;
  childName: string;
  sex: string;
}

const initialChildInfo = {
  childBirthdayDay: "",
  childBirthdayMonth: "",
  childBirthdayYear: "",
  childName: "",
  sex: "",
};

interface SummaryType {
  database: string;
  calculated: string;
}

const initialSummary = {
  database: "",
  calculated: "",
};

interface ResultEditStates {
  [key: string]:
    | string
    | boolean
    | Array<TherapistType>
    | childInfoType
    | SummaryType
    | number;
  id: string;
  therapistList: Array<TherapistType>;
  consultationLink: string;
  consultationTime: string;
  CoreProReportId: string;
  completed: boolean;
  createdAt: string;
  startAt: string;
  finishAt: string;
  uploadAt: string;
  currentStep: string;
  productId: string;
  productName: string;
  childInfo: childInfoType;
  summary: SummaryType;
  lastTherapistId: string;
  therapistId: string;
  transactionId: string;
  therapistFirstName: string;
  therapistLastName: string;
  userId: string;
  video1: string;
  video1comment: string;
  encodedVideoName1: string;
  video1aws: boolean;
  completed1: number;
  video2: string;
  video2comment: string;
  encodedVideoName2: string;
  video2aws: boolean;
  completed2: number;
  videoId: string;
  message: string;
  showVideo: boolean;
  showVideoComment: boolean;
  showReportComment: boolean;
  videoComment: string;
  reportComment: string;
  showNotification: boolean;
  showAssignTherapist: boolean;
  notificationStatus: boolean;
}

type Props = ResultEditProps & WithTranslation;
class ResultEdit extends Component<Props, ResultEditStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      id: "",
      therapistList: [],
      consultationLink: "",
      consultationTime: "",
      CoreProReportId: "",
      completed: false,
      createdAt: "",
      startAt: "",
      finishAt: "",
      uploadAt: "",
      currentStep: "",
      productId: "",
      productName: "",
      childInfo: initialChildInfo,
      summary: initialSummary,
      lastTherapistId: "",
      therapistId: "",
      therapistFirstName: "",
      therapistLastName: "",
      transactionId: "",
      userId: "",
      video1: "",
      video1comment: "",
      encodedVideoName1: "",
      video1aws: false,
      completed1: 0,
      video2: "",
      video2comment: "",
      encodedVideoName2: "",
      video2aws: false,
      completed2: 0,
      videoId: "",
      message: "",
      showVideo: false,
      showVideoComment: false,
      showReportComment: false,
      videoComment: "",
      reportComment: "",
      showNotification: false,
      showAssignTherapist: false,
      notificationStatus: false,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<ResultEditStates>,
    snapshot?: any
  ): void {
    if (this.state.therapistId !== prevState.therapistId) {
      const { therapistId } = this.state;
      const { therapistList } = this.state;
      const therapist = therapistList.filter(
        (therapist) => therapist.id === therapistId
      );
      if (therapist.length > 0) {
        this.setState({
          therapistFirstName: therapist[0].firstName,
          therapistLastName: therapist[0].lastName,
        });
      }
    }
  }

  componentDidMount = async () => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const res = await axios.get(api_address + "api/admin/result/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { result, therapistList } = res.data;
      const {
        steps,
        therapistId,
        video1,
        video1comment,
        video1aws,
        video2,
        video2comment,
        video2aws,
        finishAt,
        uploadAt,
        startAt,
        consultationTime,
        summary,
      } = result;
      let therapistFirstName = "",
        therapistLastName = "";

      if (therapistId) {
        ({ therapistFirstName, therapistLastName } =
          await this.getTherapistName(therapistId));
      }
      this.setState({
        ...result,
        id,
        lastTherapistId: therapistId,
        therapistList,
        therapistFirstName: therapistFirstName,
        therapistLastName: therapistLastName,
        startAt: startAt && moment(startAt).format("YYYY-MM-DDThh:mm"),
        finishAt: finishAt && moment(finishAt).format("YYYY-MM-DDThh:mm"),
        uploadAt: uploadAt && moment(finishAt).format("YYYY-MM-DDThh:mm"),
        consultationTime:
          consultationTime &&
          moment(consultationTime).format("YYYY-MM-DDThh:mm"),
        childInfo: steps.child_info.values,
        encodedVideoName1: video1aws ? video1 : "",
        encodedVideoName2: video2aws ? video2 : "",
        summary,
      });
    } catch (err: any) {
      console.error(err.message);
    }
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showNotification: true,
    });
    setTimeout(() => {
      this.setState({ showNotification: false });
    }, 3000);
  };

  handleResultMethodChange = (value: string) => {
    if (value != null)
      this.setState({
        resultMethod: value,
      });
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    this.setState({
      [name]: value,
    });
  };

  handleOpenVideoModal = () => {
    this.setState({
      showVideo: true,
    });
  };

  handleSaveVideo = async () => {
    try {
      const {
        video1,
        video2,
        encodedVideoName1,
        encodedVideoName2,
        video1aws,
        video2aws,
        transactionId,
      } = this.state;
      const data = {
        transactionid: transactionId,
        video1: video1aws ? encodedVideoName1 : video1,
        video2: video2aws ? encodedVideoName2 : video2,
        video1aws,
        video2aws,
      };
      const token = Cookies.get("token");
      const res = await axios.post(
        api_address + "api/results/videoUploadByAdmin",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { videoid } = res.data.result;
      this.setState({
        showVideo: false,
        videoId: videoid,
      });
    } catch (err: any) {
      this.showNotification(err.message, false);
    }
  };

  handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files, id } = e.currentTarget;
    if (!files || files.length === 0) return;
    const f = files[0];
    const uploadClass = this;
    const {
      transactionId,
      video1,
      video2,
      encodedVideoName1,
      encodedVideoName2,
      video1aws,
      video2aws,
    } = this.state;
    const isVideo1 = id === "Upload_1";
    const isVideo2 = id === "Upload_2";

    const flakeId = SNOWFLAKE.gen();
    const splitFileName = f.name.split(".");
    const fileNameExtension = "." + splitFileName[splitFileName.length - 1];
    const encodedFileName = flakeId + fileNameExtension;

    const updatedVideo1 = isVideo1 ? f.name : video1;
    const updatedVideo1AWS = isVideo1 ? true : video1aws;
    const updatedEncodedVideoName1 = isVideo1
      ? encodedFileName
      : encodedVideoName1;
    const updatedVideo2 = isVideo2 ? f.name : video2;
    const updatedVideo2AWS = isVideo2 ? true : video2aws;
    const updatedEncodedVideoName2 = isVideo2
      ? encodedFileName
      : encodedVideoName2;

    Uploader(f, transactionId, encodedFileName)
      .on("httpUploadProgress", function (evt) {
        const uploaded = Math.round((evt.loaded / evt.total) * 100);
        if (isVideo1) {
          uploadClass.setState({ completed1: uploaded });
        } else if (isVideo2) {
          uploadClass.setState({ completed2: uploaded });
        }
      })
      .send(function (err, data) {
        if (err) {
          return;
        }
        uploadClass.setState({
          video1: updatedVideo1,
          video2: updatedVideo2,
          video1aws: updatedVideo1AWS,
          video2aws: updatedVideo2AWS,
          encodedVideoName1: updatedEncodedVideoName1,
          encodedVideoName2: updatedEncodedVideoName2,
        });
      });
  };

  handleCloseVideoModal = () => {
    this.setState({
      showVideo: false,
    });
  };

  handleOpenAssignTherapistModal = () => {
    this.setState({
      showAssignTherapist: true,
    });
  };

  handleChangeTherapist = (therapistId: string) => {
    this.setState({ therapistId: therapistId });
  };

  handleSetCompletedTrue = () => {
    this.setState({ completed: true });
  };

  handleSetCompletedFalse = () => {
    this.setState({ completed: false });
  };

  handleSubmitAssignTherapist = async () => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const {
        id,
        videoId,
        therapistId,
        therapistList,
        childInfo,
        productId,
        userId,
      } = this.state;
      const {
        childName,
        childBirthdayDay,
        childBirthdayMonth,
        childBirthdayYear,
        sex,
      } = childInfo;
      const childDob =
        childBirthdayYear + "-" + childBirthdayMonth + "-" + childBirthdayDay;
      var gender = "undefined";
      if (sex === "1") {
        gender = "male";
      } else if (sex === "2") {
        gender = "female";
      }

      const data = { therapistId, resultId: id, id: videoId };

      const userData = await axios.get(
        api_address + "api/admin/user/" + userId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const userUuid = userData.data.user.UUID;

      const productData = await axios.get(
        api_address + "api/admin/product/" + productId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const productUuid = productData.data.product.uid;
      const therapist = therapistList.filter(
        (therapist) => therapist.id === therapistId
      );
      const addChildrenRes = await client.mutate({
        mutation: ADD_CHILDREN_MUTATION,
        variables: {
          childName: childName,
          gender: gender,
          dob: childDob,
          therapistUuid: therapist[0].UUID,
          uuid: userUuid,
          productUuid: productUuid,
        },
      });
      const addChildrenReturn = addChildrenRes.data.addChild;
      if (addChildrenReturn.returnMessage.status == "SUCCESS") {
        this.showNotification(addChildrenReturn.returnMessage.message, true);
        await new Promise((r) => setTimeout(r, 2000));
      } else {
        this.showNotification(addChildrenReturn.returnMessage.message, false);
        await new Promise((r) => setTimeout(r, 2000));
      }

      await axios.post(api_address + "api/admin/result/assignTherapist", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.setState({
        lastTherapistId: therapistId,
        showVideo: false,
        showAssignTherapist: false,
      });
      this.showNotification("assign successful", true);
    } catch (err) {
      console.error(err);
    }
  };

  handleCloseAssignTherapistModal = () => {
    this.setState((state) => ({
      therapistId: state.lastTherapistId,
      showAssignTherapist: false,
    }));
  };

  handleRejectVideo = () => {
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    const { id } = this.props.match.params;
    const { videoComment } = this.state;
    const data = {
      resultId: id,
      comment: videoComment,
    };
    axios
      .post(api_address + "api/admin/result/rejectVideo", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        this.setState({
          showVideoComment: false,
        });
      })
      .catch((err) => console.error(err));
  };

  onVideoCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    this.setState({
      videoComment: value,
    });
  };

  onReportCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    this.setState({
      reportComment: value,
    });
  };

  handleOpenVideoComment = () => {
    this.setState({
      showVideoComment: true,
    });
  };

  handleCloseVideoComment = () => {
    this.setState({
      showVideoComment: false,
    });
  };

  handleOpenReportComment = () => {
    this.setState({
      showReportComment: true,
    });
  };

  handleCloseReportComment = () => {
    this.setState({
      showReportComment: false,
    });
  };

  handleSubmitReport = () => {
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    const { id } = this.props.match.params;
    const { reportComment, videoId, productName } = this.state;
    const data = {
      id,
      videoId,
      reportComment,
    };
    axios
      .post(api_address + "api/admin/result/submitReport", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        this.setState({
          showReportComment: false,
        });
        if (productName === PRODUCTS.CorePro) {
          this.showNotification(
            "Submit successful, remember to set the consultation time and zoom link",
            true
          );
          window.scrollTo(0, 0);
        } else if (productName === PRODUCTS.CPC) {
          this.showNotification(
            "submit successful, remember to prepare for the onboarding",
            true
          );
        }
      })
      .catch((err) => console.error(err));
  };

  handleEditReport = () => {
    const { id } = this.props.match.params;
    this.props.history.push("/admin/report-generation/" + id, "_blank");
  };

  onCancelClick = () => {
    this.props.history.push("/admin/result");
  };

  onSaveClick = () => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const { consultationLink, consultationTime, completed } = this.state;
      axios
        .put(
          api_address + "api/admin/result/" + id,
          {
            consultationLink,
            consultationTime,
            completed,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const { status, message } = res.data;
          this.showNotification(message, status);
        })
        .catch((err) => {
          this.showNotification(err.message, false);
        });
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  getTherapistName = async (
    therapistId: number
  ): Promise<{
    therapistFirstName: string;
    therapistLastName: string;
  }> => {
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    const res = await axios.get(
      api_address + "api/admin/therapist/" + therapistId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const {
      therapist: { therapistData },
    } = res.data;
    return {
      therapistFirstName: therapistData.firstName,
      therapistLastName: therapistData.lastName,
    };
  };

  render = () => {
    const { t } = this.props;
    const { id } = this.props.match.params;
    const {
      showNotification,
      showVideo,
      message,
      notificationStatus,
      completed,
      currentStep,
      productId,
      productName,
      childInfo,
      therapistId,
      transactionId,
      therapistFirstName,
      therapistLastName,
      userId,
      video1,
      video1comment,
      video1aws,
      video2,
      video2comment,
      video2aws,
      videoId,
      encodedVideoName1,
      encodedVideoName2,
      completed1,
      completed2,
      showAssignTherapist,
      therapistList,
      showVideoComment,
      showReportComment,
      startAt,
      finishAt,
      uploadAt,
      CoreProReportId,
      consultationLink,
      consultationTime,
      summary,
    } = this.state;
    const {
      childName,
      childBirthdayDay,
      childBirthdayMonth,
      childBirthdayYear,
      sex,
    } = childInfo;
    const { year, month } = getYearAndMonth(
      childBirthdayYear,
      childBirthdayMonth,
      childBirthdayDay
    );
    const video = {
      video1,
      video1comment,
      video1aws,
      video2,
      video2comment,
      video2aws,
      encodedVideoName1,
      encodedVideoName2,
      completed1,
      completed2,
      transactionId,
    };
    const isExpert = EXPERT_PRODUCTS_FOR_ADMIN_PANEL.includes(productName);
    const hasReport = !EXPERT_PRODUCTS_WITHOUT_REPORT.includes(productName);
    const isChildInfoOnly = PRODUCTS_WITH_CHILD_INFO_ONLY.includes(productName);
    const therapistName =
      therapistFirstName && therapistLastName
        ? `${therapistFirstName} ${therapistLastName}`
        : "To be Assigned";
    return (
      <Container>
        <AssignTherapistModal
          therapistId={therapistId}
          therapistList={therapistList}
          show={showAssignTherapist}
          handleClose={this.handleCloseAssignTherapistModal}
          handleAssignTherapist={this.handleSubmitAssignTherapist}
          handleChangeTherapist={this.handleChangeTherapist}
        />
        <CommentModal
          title="Tell user about the reason"
          show={showVideoComment}
          handleSubmit={this.handleRejectVideo}
          handleClose={this.handleCloseVideoComment}
          onCommentChange={this.onVideoCommentChange}
        />
        <CommentModal
          title="Tell therapist about your changes"
          show={showReportComment}
          handleSubmit={this.handleSubmitReport}
          handleClose={this.handleCloseReportComment}
          onCommentChange={this.onReportCommentChange}
        />
        <VideoModal
          show={showVideo}
          video={video}
          handleSave={this.handleSaveVideo}
          handleClose={this.handleCloseVideoModal}
          handleUploadFile={this.handleUploadFile}
        />
        <NotificationModal
          show={showNotification}
          message={message}
          status={notificationStatus}
        />
        <SubContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.id")}</ContentSubtitle>
            <Input disabled={true} value={id} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.transactionId")}</ContentSubtitle>
            <Input disabled={true} value={transactionId} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.userId")}</ContentSubtitle>
            <LinkButtonInEdit
              onClick={() => {
                this.props.history.push("/admin/user/edit/" + userId);
              }}
            >
              {userId}
            </LinkButtonInEdit>
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.productName")}</ContentSubtitle>
            <LinkButtonInEdit
              onClick={() => {
                this.props.history.push("/admin/product/edit/" + productId);
              }}
            >
              {productName}
            </LinkButtonInEdit>
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.currentStep")}</ContentSubtitle>
            <Input disabled={true} value={currentStep} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.completed")}</ContentSubtitle>
            <CompletedContainer>
              <CompletedButton
                onClick={this.handleSetCompletedTrue}
                isChecked={completed}
              >
                Yes
              </CompletedButton>
              <CompletedButton
                onClick={this.handleSetCompletedFalse}
                isChecked={!completed}
              >
                No
              </CompletedButton>
            </CompletedContainer>
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.startAt")}</ContentSubtitle>
            <Input type="datetime-local" disabled={true} value={startAt} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.finishAt")}</ContentSubtitle>
            <Input type="datetime-local" disabled={true} value={finishAt} />
          </ContentContainer>

          {isExpert && (
            <>
              <ContentContainer>
                <ContentSubtitle>{t("admin.result.uploadAt")}</ContentSubtitle>
                <Input type="datetime-local" disabled={true} value={uploadAt} />
              </ContentContainer>
              <ContentContainer>
                <ContentSubtitle>{t("admin.result.videoId")}</ContentSubtitle>
                <Input disabled={true} value={videoId} />
              </ContentContainer>
              <ContentContainer>
                <ContentSubtitle>
                  {t("admin.result.coreProReportId")}
                </ContentSubtitle>
                <Input disabled={true} value={CoreProReportId} />
              </ContentContainer>
              <ContentContainer>
                <ContentSubtitle>
                  {t("admin.result.consultationLink")}
                </ContentSubtitle>
                <Input
                  name="consultationLink"
                  onChange={this.handleInputChange}
                  disabled={false}
                  value={consultationLink}
                />
              </ContentContainer>
              <ContentContainer>
                <ContentSubtitle>
                  {t("admin.result.consultationTime")}
                </ContentSubtitle>
                <Input
                  name="consultationTime"
                  onChange={this.handleInputChange}
                  type="datetime-local"
                  disabled={false}
                  value={consultationTime}
                />
              </ContentContainer>
            </>
          )}
        </SubContainer>

        {childName && (
          <ChildInfoContainer>
            <ChildInfoTitle>Core</ChildInfoTitle>
            <BasicInfoContainer>
              <BasicInfoTitle>{t("admin.result.childInfo")}</BasicInfoTitle>
              <BasicInfoContent>
                <ContentContainer>
                  <ContentSubtitle>
                    {t("admin.result.childName")}
                  </ContentSubtitle>
                  <ChildName disabled={true} value={childName} />
                </ContentContainer>
                <ContentContainer>
                  <ContentSubtitle>
                    {t("admin.result.childAge")}
                  </ContentSubtitle>
                  <ChildAge disabled={true} value={year} />
                  <ContentSubtitle>{t("admin.result.year")}</ContentSubtitle>
                  <ChildAge disabled={true} value={month} />
                  <ContentSubtitle>{t("admin.result.month")}</ContentSubtitle>
                </ContentContainer>
              </BasicInfoContent>
            </BasicInfoContainer>
            {!isChildInfoOnly && (
              <>
                <MoreInfoContainer>
                  <MoreInfoTitle>{t("admin.result.coreResult")}</MoreInfoTitle>
                  <ContentContainer>
                    <FullResult
                      onClick={() => {
                        window.open("/admin/full-result/" + id, "_blank");
                      }}
                    >
                      {t("admin.result.fullQuestionnaire")}
                    </FullResult>
                  </ContentContainer>
                  <ContentContainer>
                    <FullResult
                      onClick={() => {
                        window.open(
                          "/admin/core-report/" + transactionId,
                          "_blank"
                        );
                      }}
                    >
                      {t("admin.result.resultPage")}
                    </FullResult>
                  </ContentContainer>
                </MoreInfoContainer>
              </>
            )}
          </ChildInfoContainer>
        )}

        {summary.database && (
          <TherapistContainer>
            <TherapistTitle>{t("admin.result.summary")}</TherapistTitle>
            <ContentContainer>
              <ContentSubtitle>{t("admin.result.database")}</ContentSubtitle>
              <Input disabled={true} value={summary.database} />
            </ContentContainer>
            <ContentContainer>
              <ContentSubtitle>{t("admin.result.calculated")}</ContentSubtitle>
              <Input disabled={true} value={summary.calculated} />
            </ContentContainer>
          </TherapistContainer>
        )}

        {isExpert && (
          <TherapistContainer>
            <TherapistTitle>Core Pro</TherapistTitle>

            <VideoContainer>
              {!isChildInfoOnly && (
                <>
                  <VideoTitle>{t("admin.result.video")}</VideoTitle>
                  <ContentContainer>
                    <Video onClick={this.handleOpenVideoModal}>
                      {t("admin.result.videoReview")}
                    </Video>
                  </ContentContainer>
                </>
              )}
              <ContentContainer>
                <ContentSubtitle>
                  {t("admin.result.therapistName")}
                </ContentSubtitle>
                <ChildName
                  disabled={true}
                  value={therapistName}
                  style={{ width: "200px" }}
                />
              </ContentContainer>
              <ContentContainer>
                <Reject onClick={this.handleOpenVideoComment}>
                  {t("admin.result.reject")}
                </Reject>
                <Assign onClick={this.handleOpenAssignTherapistModal}>
                  {t("admin.result.assign")}
                </Assign>
              </ContentContainer>
            </VideoContainer>
            {hasReport && (
              <ReportContainer>
                <ReportTitle>{t("admin.result.report")}</ReportTitle>
                <ContentContainer>
                  <Report
                    onClick={() => {
                      this.props.history.push(
                        "/admin/core-pro-report/" + id,
                        "_blank"
                      );
                    }}
                  >
                    {t("admin.result.report")}
                  </Report>
                </ContentContainer>
                <ContentContainer>
                  <Edit onClick={this.handleEditReport}>
                    {t("admin.user.edit")}
                  </Edit>
                  <Submit onClick={this.handleOpenReportComment}>
                    {t("admin.uploadAssessment.submit")}
                  </Submit>
                </ContentContainer>
              </ReportContainer>
            )}
          </TherapistContainer>
        )}

        <ContentContainer>
          <CancelButton onClick={this.onCancelClick}>
            {t("admin.common.cancel")}
          </CancelButton>
          <SaveButton onClick={this.onSaveClick}>
            {t("admin.common.save")}
          </SaveButton>
        </ContentContainer>
      </Container>
    );
  };
}

export default withTranslation()(ResultEdit);
