import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ProTable } from "@ant-design/pro-components";
import { Button, Input, ConfigProvider } from "antd";
import api_address from "../../../constants/config";
import { ADMIN_COOKIE_NAME, LIMIT } from "../../../constants/admin";
import { GET_DATE_TIME_STRING_FROM_TIMESTAMP } from "../../../util/common";
import { JS_COOKIE } from "util/auth";
import { AdminTableContainer } from "../styles";
import enUS from "antd/es/locale/en_US";
import { PRODUCTS } from "../../../constants/common";

interface PriceType {
  cny: number;
  usd: number;
  twd: number;
}

interface ProductType {
  id: string;
  name: string;
  price: PriceType;
  createdat: string;
}

interface SorterState {
  field: string;
  order: "ascend" | "descend" | undefined;
}

const Product: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [productData, setProductData] = useState<ProductType[]>([]);
  const [filteredData, setFilteredData] = useState<ProductType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sorter, setSorter] = useState<SorterState | null>(null);
  const [filters, setFilters] = useState<{
    name?: string[];
    externalName?: string[];
  }>({});
  const [searchText, setSearchText] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(25);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getProductData = async (page: number = 1, pageSize: number = LIMIT) => {
    setLoading(true);
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const offset = (page - 1) * pageSize;
      const { data } = await axios.get(api_address + "api/admin/product", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          offset: offset,
          limit: pageSize,
        },
      });
      const sortedData = sortData(data.product, sorter);
      setProductData(sortedData);
      setFilteredData(sortedData);
      setTotal(data.rowCount);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProductData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [filters, sorter, productData, searchText]);

  const applyFiltersAndSort = () => {
    let result = [...productData];

    if (searchText) {
      result = result.filter((product) =>
        Object.values(product).some((value) =>
          String(value).toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }

    if (filters.name && filters.name.length > 0) {
      result = result.filter((product) => filters.name!.includes(product.name));
    }

    if (filters.externalName && filters.externalName.length > 0) {
      result = result.filter((product) =>
        filters.externalName!.includes(t(`admin.product.${product.name}`))
      );
    }

    result = sortData(result, sorter);

    setFilteredData(result);
    setTotal(result.length);
  };

  const productFilters = Object.keys(PRODUCTS).map((key) => ({
    text: PRODUCTS[key].replace(/([A-Z])/g, " $1").trim(),
    value: PRODUCTS[key],
  }));

  const sortData = (data: ProductType[], currentSorter: SorterState | null) => {
    if (!currentSorter) return data;

    return [...data].sort((a, b) => {
      if (currentSorter.order === "ascend") {
        return a[currentSorter.field] > b[currentSorter.field] ? 1 : -1;
      } else if (currentSorter.order === "descend") {
        return a[currentSorter.field] < b[currentSorter.field] ? 1 : -1;
      }
      return 0;
    });
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const newSorter: SorterState = {
      field: sorter.field,
      order: sorter.order,
    };
    setSorter(newSorter);
    setFilters(filters);
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.current);
    getProductData(pagination.current, pagination.pageSize);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  const columns = [
    {
      title: t("admin.product.id"),
      dataIndex: "id",
      sorter: true,
    },
    {
      title: t("admin.product.name"),
      dataIndex: "name",
      sorter: false,
      filters: productFilters,
      filterMultiple: true,
    },
    {
      title: t("admin.product.externalName"),
      dataIndex: "name",
      render: (text: string) => t(`admin.product.${text}`),
      filters: productFilters,
      filterMultiple: true,
    },
    {
      title: t("admin.product.cny"),
      dataIndex: ["price", "cny"],
      sorter: true,
    },
    {
      title: t("admin.product.twd"),
      dataIndex: ["price", "twd"],
      sorter: true,
    },
    {
      title: t("admin.product.usd"),
      dataIndex: ["price", "usd"],
      sorter: true,
    },
    {
      title: t("admin.product.createdAt"),
      dataIndex: "createdat",
      render: GET_DATE_TIME_STRING_FROM_TIMESTAMP,
      sorter: true,
    },
  ];

  return (
    <ConfigProvider locale={enUS}>
      <AdminTableContainer>
        <Input.Search
          placeholder={"Product Name"}
          onChange={handleSearch}
          style={{ marginBottom: 16 }}
        />
        <ProTable<ProductType>
          columns={columns}
          dataSource={filteredData}
          loading={loading}
          rowKey="id"
          pagination={{
            showQuickJumper: true,
            total: total,
            pageSize: pageSize,
            current: currentPage,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "25", "50", "100"],
          }}
          search={false}
          dateFormatter="string"
          headerTitle={t("admin.product.title")}
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setSelectedRowKeys(selectedRowKeys);
            },
          }}
          toolBarRender={() => [
            <Button
              key="create"
              onClick={() => history.push("/admin/product/create")}
            >
              {t("admin.product.create")}
            </Button>,
            selectedRowKeys.length === 1 && (
              <Button
                key="edit"
                onClick={() =>
                  history.push(`/admin/product/edit/${selectedRowKeys[0]}`)
                }
              >
                {t("admin.product.edit")}
              </Button>
            ),
            selectedRowKeys.length === 1 && (
              <Button
                key="createCoupon"
                onClick={() =>
                  history.push(`/admin/coupon/create/${selectedRowKeys[0]}`)
                }
              >
                {t("admin.coupon.create")}
              </Button>
            ),
          ]}
          onChange={handleTableChange}
        />
      </AdminTableContainer>
    </ConfigProvider>
  );
};

export default Product;
