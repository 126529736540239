import Admin from "components/Admin";
import UserEdit from "components/Admin/User/Edit";
import CreateCoupon from "components/Admin/Coupon/Create";
import CreateUser from "components/Admin/User/Create";
import CreateOrganization from "components/Admin/Organization/Create";
import OrganizationEdit from "components/Admin/Organization/Edit";
import CreateTherapist from "components/Admin/Therapist/Create";
import TherapistEdit from "components/Admin/Therapist/Edit";
import ProductEdit from "components/Admin/Product/Edit";
import PaymentEdit from "components/Admin/Payment/Edit";
import CouponEdit from "components/Admin/Coupon/Edit";
import ResultEdit from "components/Admin/Result/Edit";
import ReportGeneration from "components/Therapist/ReportGeneration";
import FullResult from "components/Therapist/fullResult";
import CoreProReport from "components/User/Evaluation/CorePro/CoreProReport";
import CoreReport from "components/User/Evaluation/Core/CoreReport";
import NavMenu from "components/general/NavMenu";
import I18nEditor from "components/Admin/I18nEditor";

import { Route, Switch } from "react-router";
import { AdminRouteContainer, AdminPageContainer } from "styles";
import PublicRoute from "./PublicRoute";

const AdminRoute = () => {
  return (
    <>
      <AdminPageContainer>
        <NavMenu />
        <AdminRouteContainer>
          <Switch>
            <Route exact path="/admin/:component" component={Admin} />
            <Route path="/admin/user/edit/:id" component={UserEdit} />
            <Route
              path="/admin/coupon/create/:productId"
              component={CreateCoupon}
            />
            <Route exact path="/admin/user/create" component={CreateUser} />
            <Route exact path="/admin/i18n" component={I18nEditor} />
            <Route
              exact
              path="/admin/organization/create"
              component={CreateOrganization}
            />
            <Route
              exact
              path="/admin/organization/edit/:id"
              component={OrganizationEdit}
            />
            <Route
              exact
              path="/admin/therapist/create"
              component={CreateTherapist}
            />
            <Route path="/admin/therapist/edit/:id" component={TherapistEdit} />
            <Route path="/admin/product/edit/:id" component={ProductEdit} />
            <Route path="/admin/payment/edit/:id" component={PaymentEdit} />
            <Route path="/admin/coupon/edit/:id" component={CouponEdit} />
            <Route path="/admin/result/edit/:id" component={ResultEdit} />
            <Route
              path="/admin/report-generation/:resultId"
              component={ReportGeneration}
            />
            <Route path="/admin/full-result/:resultId" component={FullResult} />
            <Route
              path="/admin/core-report/:transactionId"
              component={CoreReport}
            />
            <Route
              path="/admin/core-pro-report/:resultId"
              component={CoreProReport}
            />
            <PublicRoute />
          </Switch>
        </AdminRouteContainer>
      </AdminPageContainer>
    </>
  );
};

export default AdminRoute;
